<template>
  <v-footer id="home-footer" class="justify-center" color="black" dark min-height="72">
    <div class="title font-weight-light grey--text text--lighten-1 text-center">
      &copy; {{ new Date().getFullYear() }} — FleeTOP Technologies
      <br />E-mail : sales@fleetop.net , Mobile : +91 6364887210/11/12
       <br />
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter"
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
